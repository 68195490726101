
.l-header-banner {
  position: relative;
  .header-slider {
    bottom: 150px;
    width: 60vw;
    right: 0;
    left: 0;
    margin: 0 auto;
    text-align: center;
    top: 30vh;
    position: absolute;
    @media screen and(max-width: 768px) {
      margin: 0;
      padding: 0 10px;
      width: 100%;
    }
    &__slide {
      h2 {
        font-size: 5vmax;
        color: $white-color;
        @media screen and(max-width: 768px) {
          font-size: 8vmax;
        }
        @media screen and(max-width: 575px) {
          font-size: 32px;
        }
      }
      p {
        padding: 15px;
        letter-spacing: 1.5px;
        font-size: 17px;
      }
    }
  }
  .slick-slide {
    position: absolute!important;
    top: 0!important;
    left: 0!important;
  }
}
