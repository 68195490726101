.container {
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 15px;
  @media screen and (max-width: 1600px){
    max-width: 1400px;
  }
  @media screen and (max-width: 1400px){
    max-width: 1100px;
  }
  @media screen and (max-width: 1200px){
    max-width: 920px;
  }
  @media screen and (max-width: 992px){
    max-width: 720px;
  }
  @media screen and (max-width: 768px){
    max-width: 540px;
  }
  @media screen and (max-width: 575px){
    max-width: 480px;
  }
  @media screen and (max-width: 480px){
    max-width: 100%;
  }
}
