.l-header-banner {
  width: 100%;
  height: 100vh;
  overflow: hidden;

  .background-img-container {
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom,
            rgba(252, 227, 138, 0.5) 0%,
            rgba(255, 153, 50, 0.9) 100%),
    url('../../img/header-bg.jpeg');
    background-size: cover;
    animation: zoomin 25s infinite;
    animation: zoomout 25s infinite;
    transition: all 3s ease-in-out;
  }

}

.scroll-button {
  display: flex;
  justify-content: center;
  position: absolute;
  width: 122px;
  height: 50px;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 20px;
  text-align: center;
  cursor: pointer;
  //z-index: 10;

  .mouse {
    display: flex;
    justify-content: center;
    position: relative;
    width: 35px;
    height: 55px;

    .arrow-down {
      position: absolute;
      display: block;
      font-size: 28px;
      color: $white-color;
      animation: ani-arrow 3s linear infinite;
    }
  }

  p {
    text-align: center;
    position: absolute;
    top: -30px;
    opacity: 0;
    transition: all 2s ease-in-out;
  }

  &:hover {
    .mouse {
      border-color: $grey-hover-color;
      transition: all 0.5s ease-in-out;
    }
  }
}

@import "../modules/m-header-slider";
@keyframes ani-arrow {
  0% {
    opacity: 1;
    top: 10%;
  }
  15% {
    opacity: 1;
    top: 50%;
  }
  50% {
    opacity: 0;
    top: 50%;
  }
  100% {
    opacity: 0;
    top: 10%;
  }
}

@keyframes zoomin {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.75);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes zoomout {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.75);
  }
  100% {
    transform: scale(1);
  }
}