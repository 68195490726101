.l-about-me {
  height: 500px;
  z-index: 10;
  background: linear-gradient(#F0F0F0 20%, transparent),
          url("../../img/about-me-img.jpg");
  background-size: cover;
  position: relative;
  overflow: hidden;
  &::after {
    content:'';
    width: 50vw;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    border-bottom: 100vh solid $grey-color;
    border-right: 22vw solid transparent;
    opacity: 0.75;
    z-index: 0;
    @media screen and (max-width: 480px){
      width: 75vw;
    }
  }
  .m-section-header {
    position: relative;
    &::after {
      content: '';
      display: block;
      margin: 20px;
      width: 70px;
      height: 3px;
      background-color: $yellow-color;
    }
  }
  .container {
    position: absolute;
    margin: 0 auto;
    right: 0;
    left: 0;
    z-index: 10;
    //background: rgba(0,0,0,0.7);
    p {
      padding: 15px;
      background: rgba(0,0,0,0.5);
      margin-bottom: 2px;
      span {
        color: $yellow-color;
      }
    }
  }


}