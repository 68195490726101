
.mobile-nav {
  display: none;
  @media screen and(max-width: 800px) {
    display: block;
  }
  .hamburger-container {
    width: 155px;
    height: 50px;
    position: fixed;
    right: -15px;
    background-color: $grey-color;
    transform: skewX(25deg);
    z-index: 1000;
    &::before {
      content: '';
      position: absolute;
      left: -5px;
      width: 5px;
      height: 50px;
      background-color: $yellow-color;
    }
    .hamburger {
      width: 30px;
      height: 25px;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;
      transform: rotate(0deg);
      transform: skewX(-25deg);
      transition: 0.5s ease-in-out;
      cursor: pointer;
      .line {
        display: block;
        position: absolute;
        height: 2px;
        width: 50%;
        background: $yellow-color;
        opacity: 1;
        transform: rotate(0deg);
        transition: 0.25s ease-in-out;
        &:nth-child(even) {
          left: 50%;
        }
        &:nth-child(odd) {
          left:0px;
        }
        &:nth-child(1), &:nth-child(2){
          top: 0;
        }
        &:nth-child(3), &:nth-child(4){
          top: 10px;
        }
        &:nth-child(5), &:nth-child(6){
          top: 20px;
        }
      }
    }
    .open {
      .line {
        &:nth-child(1), &:nth-child(6) {
          transform: rotate(45deg);
        }
        &:nth-child(2), &:nth-child(5) {
          transform: rotate(-45deg);
        }
        &:nth-child(1) {
          left: 2px;
          top: 7px;
        }
        &:nth-child(2) {
          left: calc(50% - 2px);
          top: 7px;
        }
        &:nth-child(3) {
          left: -50%;
          opacity: 0;
        }
        &:nth-child(4) {
          left: 100%;
          opacity: 0;
        }
        &:nth-child(5) {
          left: 2px;
          top: 17px;
        }
        &:nth-child(6) {
          left: calc(50% - 2px);
          top: 17px;
        }
      }
    }
  }
  &__items {
    width: 100%;
    max-width: 480px;
    height: 0;
    background-color: $grey-color;
    top: -100vh;
    right: 0px;
    position: fixed;
    //z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.4s ease-in-out;
    opacity: 0;
    @media screen and(min-width: 480px) {
      max-width: 100%;
      //right: -800px;
    }
    ul {
      li {
        width: auto;
        margin-bottom: 10px;
        background-color: rgba(0,0,0,0);
        @media screen and(min-width: 480px) {
          margin-bottom: 6vh;
        }
        a {
          font-size: 26px;
          letter-spacing: 2px;
          @media screen and(max-width: 480px) {
            font-size: 24px;
          }
        }
      }
    }
    &:after{
      content: '';
      bottom: 0;
      position: absolute;
      border-bottom: 80px solid $yellow-color;
      border-right: 100vw solid transparent;
      @media screen and(min-width: 480px) {
        border-right: min(800px) solid transparent;
      }
    }
  }
  .mobile-ul-active {
    height: 100vh;
    opacity: 1;
    top: 0;
  }
}