.l-footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100vh;
  //padding-top: 25vh;
  background-image: url("../../img/footer-bg.jpg");
  background-size: cover;
  background-position: center;


  article {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 15px;
    margin-bottom: 50px;
    @media screen and (max-width: 768px) {
      margin: 0 0 5px 0 !important;
    }
  }

  article:nth-child(2) {
    text-align: center;
    margin: 0 30px 50px 30px;
    @media screen and (max-width: 768px) {
      width: 45%;
    }
    @media screen and (max-width: 575px) {
      max-width: 100%;
      width: 100%;
    }
  }

  article:nth-child(3) {
    @media screen and (max-width: 768px) {
      width: 40%;
    }
    @media screen and (max-width: 575px) {
      max-width: 100%;
      width: 100%;
    }

    h5 {
      @media screen and (min-width: 768px) {
        text-align: right;
      }
    }

    p {
      margin-bottom: 10px;
      @media screen and (max-width: 1024px) {
        font-size: 13px;
      }
    }
  }

  .footer-blocks {
    width: 100%;
    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
    }
    @media screen and (max-width: 575px) {
      width: auto;
    }
  }

  ul {
    li {
      width: 100%;
      height: 45px;
      justify-content: flex-start;

      .link {
        font-size: 16px;
      }
    }
  }

  .site-map {
    ul {
      @media screen and (max-width: 768px) {
        display: flex;
      }
    }
  }

  .follow-me {

    .social-link {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      color: black;
      width: 50px;
      margin: 10px 10px;
      height: 27.5px;
      background: $yellow-color;
      position: relative;
      transition: all 0.35s ease-in-out;

      &:hover {
        color: white;
        background: $grey-color-op;
        transition: all 0.35s ease-in-out;

        &::before, &::after {
          transition: all 0.35s ease-in-out;
          border-bottom-color: $grey-color-op;
          border-top-color: $grey-color-op;
        }
      }
    }

    .social-link:nth-child(2n-1) {
      margin-top: 52px;
    }

    .social-link:nth-child(2n) {
      margin-left: -34px;
      margin-right: -34px;
    }

    .linkedin:hover {
      color: #0077b5;
    }
    .telegram:hover {
      color: #0088cc;
    }
    .skype:hover {
      color: #00aff0;
    }
    .pinterest:hover {
      color: #bd081c;
    }


    .social-link::before {
      content: "";
      position: absolute;
      top: -12.5px;
      left: 0;
      width: 0;
      height: 0;
      border-left: 25px solid transparent;
      border-right: 25px solid transparent;
      border-bottom: 12.5px solid $yellow-color;
      transition: all 0.35s ease-in-out;
    }

    .social-link::after {
      content: "";
      position: absolute;
      bottom: -12.5px;
      left: 0;
      width: 0;
      height: 0;
      border-left: 25px solid transparent;
      border-right: 25px solid transparent;
      border-top: 12.5px solid $yellow-color;
      transition: all 0.35s ease-in-out;
    }

  }

  .footer-panel {
    width: 100vw;
    height: 50px;
    background-color: rgba(0, 0, 0, 0.8);
    font-size: 13px;

    .container {
      width: 100%;
      margin: 0 auto;
    }
  }

}