.l-cv-main {
  display: flex;
  width: 100vw;
  height: 155vh;
  z-index: 100;
  background-color: $grey-bg-color;
  overflow: hidden;
  &::after {
    content: '';
    width: 0;
    position: absolute;
    bottom: -20vh;
    left: 0;
    border-top: 20vh solid $grey-bg-color;
    border-left: 100vw solid transparent;
    @media screen and (max-width: 575px) {
      bottom: -10vh;
      border-top: 10vh solid $grey-bg-color;
    }
  }
  @media screen and (max-width: 768px){
    flex-direction: column;
    height: auto;
  }

  .side-part {
    width: 400px;
    height: 100%;
    background-color: $grey-color;
    padding: 20px;
    @media screen and (min-width: 768px){
      &::after {
        content: '';
        width: 0;
        position: absolute;
        bottom: 0;
        left: -500px;
        border-bottom: 20vh solid #f0f0f0;
        border-right: 100vw solid transparent;
      }
    }

    @media screen and (max-width: 768px){
      width: 100%;
    }

    h4 {
      color: white;
      font-family: $header-font;

      &::after {
        content: '';
        display: block;
        width: 70px;
        height: 3px;
        background-color: $yellow-color;
        margin-top: 10px;
      }
    }
    p {
      font-size: 14px;
      padding-bottom: 5px;
    }

    .name {
      font-size: 18px;
    }

    .language-paragraph {
      width: 115px;

    }

    .romb {
      width: 11px;
      height: 11px;
      background-color: $grey-bg-color;
      transform: skew(25deg);
      margin: 0 3px;
    }
    .empty-romb {
      background-color: slategray;
    }

  }

  .main-part {
    width: 100%;
    height: 100%;
    background-color: $grey-bg-color;
    padding: 20px;
    @media screen and (max-width: 768px){
      width: unset;
      margin-bottom: 100px;
    }
  }

}