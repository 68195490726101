header {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  position: fixed;
  transition: all 0.5s ease-in-out;
  z-index: 1000;
  .logo {
    position: absolute;
    left: -12px;
    width: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    background-color: $grey-color-op;
    transform: skewX(25deg);
    z-index: 1000;
    a {
      h1 {
        transform: skewX(-25deg);
        &::first-letter {
          color: $yellow-color;
        }
        &:hover {
          &::first-letter {
            color: $orange-color;
          }
        }
      }
    }
    &::after {
      position: absolute;
      right: -1px;
      content: '';
      width: 5px;
      height: 50px;
      background-color: $yellow-color;
    }
  }
  .desktop-nav {
    position: absolute;
    right: -12px;
    z-index: 1000;
    @media screen and(max-width: 800px) {
      display: none;
    }
    ul {
      display: flex;
      transform: skewX(25deg);
      li {
        position: relative;
        background-color: $grey-color-op;
        &::before {
          position: absolute;
          left: 0;
          //top: 0;
          content: '';
          width: 5px;
          height: 0px;
          background-color: $yellow-color;
          transition: all 0.5s ease-in-out;
        }
        &:hover {
          &::before {
            height: 100%;
            transition: all 0.5s ease-in-out;
          }
        }
        a {
          transform: skewX(-25deg);
        }
      }
    }
  }
}
@import "../modules/m-mobile-menu";
@import "../modules/m-dropdown-list";