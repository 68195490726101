
nav {
  ul {
    .m-dropdown-list {
      position: relative;
      span {
        cursor: pointer;
        transform: skewX(-25deg);
      }
      .ul-dropdown {
        position: absolute;
        top: 50px;
        display: flex;
        flex-direction: column;
        transform: skewX(0deg);
        height: 0;
        transition: all 0.5s;
        li {
          a {
            //For Firefox
            height: 0;
            //
            opacity: 0;
          }
        }
      }
      &:hover {
        transition: all 0.5s;
        .ul-dropdown {
          height: 100px;
          a {
            //For firefox
            height: 23px;
            //
            opacity: 1;
          }
        }
      }
    }
  }
}


