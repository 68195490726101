@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Montserrat);
@import url(https://fonts.googleapis.com/css?family=Expletus+Sans:400,400i);
.container {
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 15px; }
  @media screen and (max-width: 1600px) {
    .container {
      max-width: 1400px; } }
  @media screen and (max-width: 1400px) {
    .container {
      max-width: 1100px; } }
  @media screen and (max-width: 1200px) {
    .container {
      max-width: 920px; } }
  @media screen and (max-width: 992px) {
    .container {
      max-width: 720px; } }
  @media screen and (max-width: 768px) {
    .container {
      max-width: 540px; } }
  @media screen and (max-width: 575px) {
    .container {
      max-width: 480px; } }
  @media screen and (max-width: 480px) {
    .container {
      max-width: 100%; } }

.d-flex {
  display: flex; }

.center-horizontal {
  justify-content: center; }

.center-vertical {
  align-items: center; }

.flex-wrap {
  flex-wrap: wrap; }

.around {
  justify-content: space-around; }

.between {
  justify-content: space-between; }

.text-center {
  text-align: center; }

.black {
  color: #262626; }

.mt-1 {
  margin-top: 10px; }

.mt-2 {
  margin-top: 20px; }

.mt-3 {
  margin-top: 30px; }

.mt-4 {
  margin-top: 40px; }

.mt-5 {
  margin-top: 50px; }

.mt-10 {
  margin-top: 100px; }

.mb-1 {
  margin-bottom: 10px; }

.mb-2 {
  margin-bottom: 20px; }

.mb-3 {
  margin-bottom: 30px; }

.mb-4 {
  margin-bottom: 40px; }

.mb-5 {
  margin-bottom: 50px; }

.mb-10 {
  margin-bottom: 100px; }

.pt-1 {
  padding-top: 10px; }

.pt-2 {
  padding-top: 20px; }

.pt-3 {
  padding-top: 30px; }

.pt-4 {
  padding-top: 40px; }

.pt-5 {
  padding-top: 50px; }

.pt-6 {
  padding-top: 60px; }

.pt-10 {
  padding-top: 100px; }

.pb-1 {
  padding-bottom: 10px; }

.pb-2 {
  padding-bottom: 20px; }

.pb-3 {
  padding-bottom: 30px; }

.pb-4 {
  padding-bottom: 40px; }

.pb-5 {
  padding-bottom: 50px; }

.pb-6 {
  padding-bottom: 60px; }

.pb-10 {
  padding-bottom: 100px; }

* {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden; }

html {
  scroll-behavior: smooth; }

.container-wrapper {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden; }

body {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: #f0f0f0; }

main {
  position: relative;
  margin-bottom: 100vh;
  z-index: 10; }

footer {
  bottom: 0;
  position: fixed;
  z-index: 1; }

ul {
  margin: 0;
  padding: 0; }

li {
  width: 150px;
  height: 50px;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center; }

span {
  margin: 0;
  padding: 0;
  color: #ffffff;
  font-family: "Roboto", sans-serif; }

a {
  color: #ffffff;
  text-decoration: none;
  font-family: "Roboto", sans-serif; }
  a:hover {
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    color: #fce38a; }

.link {
  font-size: 18px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s; }
  .link:hover {
    color: #fce38a;
    -webkit-transition: all 0.5s;
    transition: all 0.5s; }

h1 {
  margin: 0;
  padding: 0;
  font-size: 28px;
  color: #ffffff;
  font-family: "Montserrat", sans-serif; }

h2 {
  margin: 0;
  font-size: 48px;
  font-family: "Montserrat", sans-serif; }

h3 {
  margin: 0;
  font-size: 24px;
  font-family: "Expletus Sans", cursive; }

h4 {
  font-size: 20px;
  font-family: "Expletus Sans", cursive;
  margin: 0 0 5px 0; }

h5 {
  font-size: 20px;
  color: white;
  font-family: "Expletus Sans", cursive;
  margin: 10px 0 10px 0; }

p {
  margin: 0;
  padding: 0;
  color: white;
  font-family: "Roboto", sans-serif; }
  p::-moz-selection {
    background-color: #fce38a; }
  p::selection {
    background-color: #fce38a; }

blockquote {
  color: white;
  font-family: "Roboto", sans-serif; }

label {
  font-family: "Montserrat", sans-serif; }

header {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  position: fixed;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  z-index: 1000; }
  header .logo {
    position: absolute;
    left: -12px;
    width: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    background-color: rgba(38, 38, 38, 0.9);
    -webkit-transform: skewX(25deg);
            transform: skewX(25deg);
    z-index: 1000; }
    header .logo a h1 {
      -webkit-transform: skewX(-25deg);
              transform: skewX(-25deg); }
      header .logo a h1::first-letter {
        color: #fce38a; }
      header .logo a h1:hover::first-letter {
        color: #ff9932; }
    header .logo::after {
      position: absolute;
      right: -1px;
      content: '';
      width: 5px;
      height: 50px;
      background-color: #fce38a; }
  header .desktop-nav {
    position: absolute;
    right: -12px;
    z-index: 1000; }
    @media screen and (max-width: 800px) {
      header .desktop-nav {
        display: none; } }
    header .desktop-nav ul {
      display: flex;
      -webkit-transform: skewX(25deg);
              transform: skewX(25deg); }
      header .desktop-nav ul li {
        position: relative;
        background-color: rgba(38, 38, 38, 0.9); }
        header .desktop-nav ul li::before {
          position: absolute;
          left: 0;
          content: '';
          width: 5px;
          height: 0px;
          background-color: #fce38a;
          -webkit-transition: all 0.5s ease-in-out;
          transition: all 0.5s ease-in-out; }
        header .desktop-nav ul li:hover::before {
          height: 100%;
          -webkit-transition: all 0.5s ease-in-out;
          transition: all 0.5s ease-in-out; }
        header .desktop-nav ul li a {
          -webkit-transform: skewX(-25deg);
                  transform: skewX(-25deg); }

.mobile-nav {
  display: none; }
  @media screen and (max-width: 800px) {
    .mobile-nav {
      display: block; } }
  .mobile-nav .hamburger-container {
    width: 155px;
    height: 50px;
    position: fixed;
    right: -15px;
    background-color: #262626;
    -webkit-transform: skewX(25deg);
            transform: skewX(25deg);
    z-index: 1000; }
    .mobile-nav .hamburger-container::before {
      content: '';
      position: absolute;
      left: -5px;
      width: 5px;
      height: 50px;
      background-color: #fce38a; }
    .mobile-nav .hamburger-container .hamburger {
      width: 30px;
      height: 25px;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      -webkit-transform: skewX(-25deg);
              transform: skewX(-25deg);
      -webkit-transition: 0.5s ease-in-out;
      transition: 0.5s ease-in-out;
      cursor: pointer; }
      .mobile-nav .hamburger-container .hamburger .line {
        display: block;
        position: absolute;
        height: 2px;
        width: 50%;
        background: #fce38a;
        opacity: 1;
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
        -webkit-transition: 0.25s ease-in-out;
        transition: 0.25s ease-in-out; }
        .mobile-nav .hamburger-container .hamburger .line:nth-child(even) {
          left: 50%; }
        .mobile-nav .hamburger-container .hamburger .line:nth-child(odd) {
          left: 0px; }
        .mobile-nav .hamburger-container .hamburger .line:nth-child(1), .mobile-nav .hamburger-container .hamburger .line:nth-child(2) {
          top: 0; }
        .mobile-nav .hamburger-container .hamburger .line:nth-child(3), .mobile-nav .hamburger-container .hamburger .line:nth-child(4) {
          top: 10px; }
        .mobile-nav .hamburger-container .hamburger .line:nth-child(5), .mobile-nav .hamburger-container .hamburger .line:nth-child(6) {
          top: 20px; }
    .mobile-nav .hamburger-container .open .line:nth-child(1), .mobile-nav .hamburger-container .open .line:nth-child(6) {
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg); }
    .mobile-nav .hamburger-container .open .line:nth-child(2), .mobile-nav .hamburger-container .open .line:nth-child(5) {
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg); }
    .mobile-nav .hamburger-container .open .line:nth-child(1) {
      left: 2px;
      top: 7px; }
    .mobile-nav .hamburger-container .open .line:nth-child(2) {
      left: calc(50% - 2px);
      top: 7px; }
    .mobile-nav .hamburger-container .open .line:nth-child(3) {
      left: -50%;
      opacity: 0; }
    .mobile-nav .hamburger-container .open .line:nth-child(4) {
      left: 100%;
      opacity: 0; }
    .mobile-nav .hamburger-container .open .line:nth-child(5) {
      left: 2px;
      top: 17px; }
    .mobile-nav .hamburger-container .open .line:nth-child(6) {
      left: calc(50% - 2px);
      top: 17px; }
  .mobile-nav__items {
    width: 100%;
    max-width: 480px;
    height: 0;
    background-color: #262626;
    top: -100vh;
    right: 0px;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    opacity: 0; }
    @media screen and (min-width: 480px) {
      .mobile-nav__items {
        max-width: 100%; } }
    .mobile-nav__items ul li {
      width: auto;
      margin-bottom: 10px;
      background-color: rgba(0, 0, 0, 0); }
      @media screen and (min-width: 480px) {
        .mobile-nav__items ul li {
          margin-bottom: 6vh; } }
      .mobile-nav__items ul li a {
        font-size: 26px;
        letter-spacing: 2px; }
        @media screen and (max-width: 480px) {
          .mobile-nav__items ul li a {
            font-size: 24px; } }
    .mobile-nav__items:after {
      content: '';
      bottom: 0;
      position: absolute;
      border-bottom: 80px solid #fce38a;
      border-right: 100vw solid transparent; }
      @media screen and (min-width: 480px) {
        .mobile-nav__items:after {
          border-right: 800px solid transparent; } }
  .mobile-nav .mobile-ul-active {
    height: 100vh;
    opacity: 1;
    top: 0; }

nav ul .m-dropdown-list {
  position: relative; }
  nav ul .m-dropdown-list span {
    cursor: pointer;
    -webkit-transform: skewX(-25deg);
            transform: skewX(-25deg); }
  nav ul .m-dropdown-list .ul-dropdown {
    position: absolute;
    top: 50px;
    display: flex;
    flex-direction: column;
    -webkit-transform: skewX(0deg);
            transform: skewX(0deg);
    height: 0;
    -webkit-transition: all 0.5s;
    transition: all 0.5s; }
    nav ul .m-dropdown-list .ul-dropdown li a {
      height: 0;
      opacity: 0; }
  nav ul .m-dropdown-list:hover {
    -webkit-transition: all 0.5s;
    transition: all 0.5s; }
    nav ul .m-dropdown-list:hover .ul-dropdown {
      height: 100px; }
      nav ul .m-dropdown-list:hover .ul-dropdown a {
        height: 23px;
        opacity: 1; }

.l-header-banner {
  width: 100%;
  height: 100vh;
  overflow: hidden; }
  .l-header-banner .background-img-container {
    width: 100%;
    height: 100%;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(252, 227, 138, 0.5)), to(rgba(255, 153, 50, 0.9))), url(/static/media/header-bg.92c0f51c.jpeg);
    background: linear-gradient(to bottom, rgba(252, 227, 138, 0.5) 0%, rgba(255, 153, 50, 0.9) 100%), url(/static/media/header-bg.92c0f51c.jpeg);
    background-size: cover;
    -webkit-animation: zoomin 25s infinite;
            animation: zoomin 25s infinite;
    -webkit-animation: zoomout 25s infinite;
            animation: zoomout 25s infinite;
    -webkit-transition: all 3s ease-in-out;
    transition: all 3s ease-in-out; }

.scroll-button {
  display: flex;
  justify-content: center;
  position: absolute;
  width: 122px;
  height: 50px;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 20px;
  text-align: center;
  cursor: pointer; }
  .scroll-button .mouse {
    display: flex;
    justify-content: center;
    position: relative;
    width: 35px;
    height: 55px; }
    .scroll-button .mouse .arrow-down {
      position: absolute;
      display: block;
      font-size: 28px;
      color: #ffffff;
      -webkit-animation: ani-arrow 3s linear infinite;
              animation: ani-arrow 3s linear infinite; }
  .scroll-button p {
    text-align: center;
    position: absolute;
    top: -30px;
    opacity: 0;
    -webkit-transition: all 2s ease-in-out;
    transition: all 2s ease-in-out; }
  .scroll-button:hover .mouse {
    border-color: #d9d9d9;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out; }

.l-header-banner {
  position: relative; }
  .l-header-banner .header-slider {
    bottom: 150px;
    width: 60vw;
    right: 0;
    left: 0;
    margin: 0 auto;
    text-align: center;
    top: 30vh;
    position: absolute; }
    @media screen and (max-width: 768px) {
      .l-header-banner .header-slider {
        margin: 0;
        padding: 0 10px;
        width: 100%; } }
    .l-header-banner .header-slider__slide h2 {
      font-size: 5vmax;
      color: #ffffff; }
      @media screen and (max-width: 768px) {
        .l-header-banner .header-slider__slide h2 {
          font-size: 8vmax; } }
      @media screen and (max-width: 575px) {
        .l-header-banner .header-slider__slide h2 {
          font-size: 32px; } }
    .l-header-banner .header-slider__slide p {
      padding: 15px;
      letter-spacing: 1.5px;
      font-size: 17px; }
  .l-header-banner .slick-slide {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important; }

@-webkit-keyframes ani-arrow {
  0% {
    opacity: 1;
    top: 10%; }
  15% {
    opacity: 1;
    top: 50%; }
  50% {
    opacity: 0;
    top: 50%; }
  100% {
    opacity: 0;
    top: 10%; } }

@keyframes ani-arrow {
  0% {
    opacity: 1;
    top: 10%; }
  15% {
    opacity: 1;
    top: 50%; }
  50% {
    opacity: 0;
    top: 50%; }
  100% {
    opacity: 0;
    top: 10%; } }

@-webkit-keyframes zoomin {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  50% {
    -webkit-transform: scale(1.75);
            transform: scale(1.75); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes zoomin {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  50% {
    -webkit-transform: scale(1.75);
            transform: scale(1.75); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes zoomout {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  50% {
    -webkit-transform: scale(1.75);
            transform: scale(1.75); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes zoomout {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  50% {
    -webkit-transform: scale(1.75);
            transform: scale(1.75); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

.l-about-skills {
  width: 100%;
  color: #f0f0f0;
  position: relative;
  padding-top: 80px;
  background: -webkit-gradient(linear, left bottom, left top, from(#f0f0f0), color-stop(50%, rgba(0, 0, 0, 0))), url(/static/media/rock-cloud.84c16922.jpeg);
  background: linear-gradient(to top, #f0f0f0 0%, rgba(0, 0, 0, 0) 50%), url(/static/media/rock-cloud.84c16922.jpeg);
  background-size: cover;
  padding-bottom: 150px; }
  .l-about-skills::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border-top: 25vh solid #262626;
    border-right: 130vw solid transparent;
    height: 0;
    margin: 0 auto;
    width: 0;
    z-index: 1;
    opacity: 0.95; }
    @media screen and (max-width: 992px) {
      .l-about-skills::before {
        border-top: 30vh solid #262626;
        border-right: 200vw solid transparent; } }
    @media screen and (max-width: 575px) {
      .l-about-skills::before {
        border-top: 25vh solid #262626;
        border-right: 250vw solid transparent; } }
  .l-about-skills .m-section-header {
    position: absolute;
    top: 40px;
    left: 40px; }
    .l-about-skills .m-section-header::after {
      position: absolute;
      top: 30px;
      right: -5px;
      content: '';
      width: 70px;
      height: 4px;
      background-color: #fce38a; }
      @media screen and (max-width: 575px) {
        .l-about-skills .m-section-header::after {
          top: 20px;
          height: 3px; } }
  .l-about-skills .geometry-blocks {
    display: flex;
    justify-content: center;
    position: relative;
    width: 500px;
    height: 150px;
    margin: 280px auto 180px auto; }
    @media screen and (max-width: 480px) {
      .l-about-skills .geometry-blocks {
        width: 100%;
        max-width: 320px;
        margin-bottom: 100px; } }
    .l-about-skills .geometry-blocks .font-icons {
      font-size: 28px; }
      @media screen and (max-width: 480px) {
        .l-about-skills .geometry-blocks .font-icons {
          font-size: 17px; } }
    .l-about-skills .geometry-blocks .html-icon {
      color: #E54C21; }
    .l-about-skills .geometry-blocks .css-icon {
      color: #0070B7;
      padding: 0 5px; }
    .l-about-skills .geometry-blocks .js-icon {
      color: #F7E018; }
    .l-about-skills .geometry-blocks .react-icon {
      color: #61DAFB; }
    .l-about-skills .geometry-blocks .bootstrap-icon {
      color: #6D43A1; }
    .l-about-skills .geometry-blocks .php-icon {
      color: #4F5B93; }
    .l-about-skills .geometry-blocks .gulp-icon {
      color: #D34A47; }
    .l-about-skills .geometry-blocks .yarn-icon {
      color: #2C8EBB; }
    .l-about-skills .geometry-blocks .npm-icon {
      color: #CC3534;
      padding-right: 5px; }
    .l-about-skills .geometry-blocks .icon-container {
      display: flex; }
    .l-about-skills .geometry-blocks .hexagon {
      width: 90px;
      height: 150px;
      background: #262626;
      position: absolute;
      display: flex;
      align-content: center;
      justify-content: center;
      -webkit-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out;
      opacity: 0.9;
      z-index: 10; }
      .l-about-skills .geometry-blocks .hexagon::before {
        content: "";
        position: absolute;
        top: 0;
        left: -45px;
        width: 0;
        height: 0;
        border-top: 75px solid transparent;
        border-bottom: 75px solid transparent;
        border-right: 45px solid #262626;
        -webkit-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out; }
      .l-about-skills .geometry-blocks .hexagon::after {
        content: "";
        position: absolute;
        right: -45px;
        width: 0;
        height: 0;
        border-top: 75px solid transparent;
        border-bottom: 75px solid transparent;
        border-left: 45px solid #262626;
        -webkit-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out; }
      @media screen and (max-width: 480px) {
        .l-about-skills .geometry-blocks .hexagon {
          width: 60px;
          height: 100px;
          background: #262626;
          position: absolute;
          display: flex;
          align-content: center;
          justify-content: center;
          -webkit-transition: all 0.5s ease-in-out;
          transition: all 0.5s ease-in-out; }
          .l-about-skills .geometry-blocks .hexagon::before {
            content: "";
            position: absolute;
            top: 0;
            left: -30px;
            width: 0;
            height: 0;
            border-top: 50px solid transparent;
            border-bottom: 50px solid transparent;
            border-right: 30px solid #262626;
            -webkit-transition: all 0.5s ease-in-out;
            transition: all 0.5s ease-in-out; }
          .l-about-skills .geometry-blocks .hexagon::after {
            content: "";
            position: absolute;
            right: -30px;
            width: 0;
            height: 0;
            border-top: 50px solid transparent;
            border-bottom: 50px solid transparent;
            border-left: 30px solid #262626;
            -webkit-transition: all 0.5s ease-in-out;
            transition: all 0.5s ease-in-out; } }
      .l-about-skills .geometry-blocks .hexagon span {
        margin: auto;
        padding-top: 5px;
        font-size: 21px; }
        @media screen and (max-width: 480px) {
          .l-about-skills .geometry-blocks .hexagon span {
            font-size: 14px; } }
      .l-about-skills .geometry-blocks .hexagon .last-span {
        font-size: 15px; }
        @media screen and (max-width: 480px) {
          .l-about-skills .geometry-blocks .hexagon .last-span {
            font-size: 10px; } }
      .l-about-skills .geometry-blocks .hexagon .back-hexagon {
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;
        -webkit-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;
        opacity: 0;
        top: 0;
        z-index: 1;
        width: 90px;
        height: 150px;
        background: #262626;
        position: absolute;
        display: flex;
        align-content: center;
        justify-content: center;
        transition: all 0.5s ease-in-out; }
        .l-about-skills .geometry-blocks .hexagon .back-hexagon::before {
          content: "";
          position: absolute;
          top: 0;
          left: -45px;
          width: 0;
          height: 0;
          border-top: 75px solid transparent;
          border-bottom: 75px solid transparent;
          border-right: 45px solid #262626;
          -webkit-transition: all 0.5s ease-in-out;
          transition: all 0.5s ease-in-out; }
        .l-about-skills .geometry-blocks .hexagon .back-hexagon::after {
          content: "";
          position: absolute;
          right: -45px;
          width: 0;
          height: 0;
          border-top: 75px solid transparent;
          border-bottom: 75px solid transparent;
          border-left: 45px solid #262626;
          -webkit-transition: all 0.5s ease-in-out;
          transition: all 0.5s ease-in-out; }
        @media screen and (max-width: 480px) {
          .l-about-skills .geometry-blocks .hexagon .back-hexagon {
            width: 60px;
            height: 100px;
            background: #262626;
            position: absolute;
            display: flex;
            align-content: center;
            justify-content: center;
            -webkit-transition: all 0.5s ease-in-out;
            transition: all 0.5s ease-in-out; }
            .l-about-skills .geometry-blocks .hexagon .back-hexagon::before {
              content: "";
              position: absolute;
              top: 0;
              left: -30px;
              width: 0;
              height: 0;
              border-top: 50px solid transparent;
              border-bottom: 50px solid transparent;
              border-right: 30px solid #262626;
              -webkit-transition: all 0.5s ease-in-out;
              transition: all 0.5s ease-in-out; }
            .l-about-skills .geometry-blocks .hexagon .back-hexagon::after {
              content: "";
              position: absolute;
              right: -30px;
              width: 0;
              height: 0;
              border-top: 50px solid transparent;
              border-bottom: 50px solid transparent;
              border-left: 30px solid #262626;
              -webkit-transition: all 0.5s ease-in-out;
              transition: all 0.5s ease-in-out; } }
        .l-about-skills .geometry-blocks .hexagon .back-hexagon span {
          margin: 0;
          font-size: 16px;
          text-align: center; }
          @media screen and (max-width: 480px) {
            .l-about-skills .geometry-blocks .hexagon .back-hexagon span {
              font-size: 11px; } }
      .l-about-skills .geometry-blocks .hexagon .second-hex {
        right: 0; }
      .l-about-skills .geometry-blocks .hexagon .sixth-hex {
        left: 0; }
      .l-about-skills .geometry-blocks .hexagon .fourth-hex-right {
        left: 0; }
    .l-about-skills .geometry-blocks .left-hexagon {
      right: 350px; }
      @media screen and (max-width: 480px) {
        .l-about-skills .geometry-blocks .left-hexagon {
          right: 225px; } }
      .l-about-skills .geometry-blocks .left-hexagon:hover {
        background: #ff9932;
        -webkit-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out; }
        .l-about-skills .geometry-blocks .left-hexagon:hover::after {
          border-left: 45px solid #ff9932; }
          @media screen and (max-width: 480px) {
            .l-about-skills .geometry-blocks .left-hexagon:hover::after {
              border-left: 30px solid #ff9932; } }
        .l-about-skills .geometry-blocks .left-hexagon:hover::before {
          border-right: 45px solid #ff9932; }
          @media screen and (max-width: 480px) {
            .l-about-skills .geometry-blocks .left-hexagon:hover::before {
              border-right: 30px solid #ff9932; } }
        .l-about-skills .geometry-blocks .left-hexagon:hover .first-hex {
          opacity: 1;
          top: 160px;
          -webkit-transition: all 0.5s ease-in-out;
          transition: all 0.5s ease-in-out; }
          @media screen and (max-width: 480px) {
            .l-about-skills .geometry-blocks .left-hexagon:hover .first-hex {
              top: 105px; } }
        .l-about-skills .geometry-blocks .left-hexagon:hover .second-hex {
          opacity: 1;
          top: 80px;
          right: 145px;
          -webkit-transition: all 0.5s ease-in-out;
          transition: all 0.5s ease-in-out; }
          @media screen and (max-width: 800px) {
            .l-about-skills .geometry-blocks .left-hexagon:hover .second-hex {
              top: -160px;
              right: 0px; } }
          @media screen and (max-width: 480px) {
            .l-about-skills .geometry-blocks .left-hexagon:hover .second-hex {
              top: -105px;
              right: 0px; } }
    .l-about-skills .geometry-blocks .center-hexagon {
      bottom: 80px; }
      @media screen and (max-width: 480px) {
        .l-about-skills .geometry-blocks .center-hexagon {
          bottom: 103px; } }
      .l-about-skills .geometry-blocks .center-hexagon:hover {
        background: #ff3333;
        -webkit-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out; }
        .l-about-skills .geometry-blocks .center-hexagon:hover::after {
          border-left: 45px solid #ff3333; }
          @media screen and (max-width: 480px) {
            .l-about-skills .geometry-blocks .center-hexagon:hover::after {
              border-left: 30px solid #ff3333; } }
        .l-about-skills .geometry-blocks .center-hexagon:hover::before {
          border-right: 45px solid #ff3333; }
          @media screen and (max-width: 480px) {
            .l-about-skills .geometry-blocks .center-hexagon:hover::before {
              border-right: 30px solid #ff3333; } }
        .l-about-skills .geometry-blocks .center-hexagon:hover .third-hex {
          opacity: 1;
          top: 160px;
          -webkit-transition: all 0.5s ease-in-out;
          transition: all 0.5s ease-in-out; }
          @media screen and (max-width: 480px) {
            .l-about-skills .geometry-blocks .center-hexagon:hover .third-hex {
              top: 105px; } }
        .l-about-skills .geometry-blocks .center-hexagon:hover .fourth-hex {
          opacity: 1;
          top: -160px;
          -webkit-transition: all 0.5s ease-in-out;
          transition: all 0.5s ease-in-out; }
          @media screen and (max-width: 480px) {
            .l-about-skills .geometry-blocks .center-hexagon:hover .fourth-hex {
              top: -105px; } }
        .l-about-skills .geometry-blocks .center-hexagon:hover .fourth-hex-right {
          opacity: 1;
          top: -80px;
          left: 145px;
          -webkit-transition: all 0.5s ease-in-out;
          transition: all 0.5s ease-in-out; }
          @media screen and (max-width: 480px) {
            .l-about-skills .geometry-blocks .center-hexagon:hover .fourth-hex-right {
              top: -53px;
              left: 95px; } }
    .l-about-skills .geometry-blocks .right-hexagon {
      left: 350px; }
      @media screen and (max-width: 480px) {
        .l-about-skills .geometry-blocks .right-hexagon {
          left: 225px; } }
      .l-about-skills .geometry-blocks .right-hexagon:hover {
        background: #2e5cb8;
        -webkit-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out; }
        .l-about-skills .geometry-blocks .right-hexagon:hover::after {
          border-left: 45px solid #2e5cb8; }
          @media screen and (max-width: 480px) {
            .l-about-skills .geometry-blocks .right-hexagon:hover::after {
              border-left: 30px solid #2e5cb8; } }
        .l-about-skills .geometry-blocks .right-hexagon:hover::before {
          border-right: 45px solid #2e5cb8; }
          @media screen and (max-width: 480px) {
            .l-about-skills .geometry-blocks .right-hexagon:hover::before {
              border-right: 30px solid #2e5cb8; } }
        .l-about-skills .geometry-blocks .right-hexagon:hover .fifth-hex {
          opacity: 1;
          top: 160px;
          -webkit-transition: all 0.5s ease-in-out;
          transition: all 0.5s ease-in-out; }
          @media screen and (max-width: 800px) {
            .l-about-skills .geometry-blocks .right-hexagon:hover .fifth-hex {
              top: -160px; } }
          @media screen and (max-width: 480px) {
            .l-about-skills .geometry-blocks .right-hexagon:hover .fifth-hex {
              top: -105px; } }
        .l-about-skills .geometry-blocks .right-hexagon:hover .sixth-hex {
          opacity: 1;
          top: 80px;
          left: 145px;
          -webkit-transition: all 0.5s ease-in-out;
          transition: all 0.5s ease-in-out; }
          @media screen and (max-width: 800px) {
            .l-about-skills .geometry-blocks .right-hexagon:hover .sixth-hex {
              top: 160px;
              left: 0; } }
          @media screen and (max-width: 480px) {
            .l-about-skills .geometry-blocks .right-hexagon:hover .sixth-hex {
              top: 105px; } }

.l-about-me {
  height: 500px;
  z-index: 10;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(20%, #F0F0F0), to(transparent)), url(/static/media/about-me-img.ec8e37ee.jpg);
  background: linear-gradient(#F0F0F0 20%, transparent), url(/static/media/about-me-img.ec8e37ee.jpg);
  background-size: cover;
  position: relative;
  overflow: hidden; }
  .l-about-me::after {
    content: '';
    width: 50vw;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    border-bottom: 100vh solid #262626;
    border-right: 22vw solid transparent;
    opacity: 0.75;
    z-index: 0; }
    @media screen and (max-width: 480px) {
      .l-about-me::after {
        width: 75vw; } }
  .l-about-me .m-section-header {
    position: relative; }
    .l-about-me .m-section-header::after {
      content: '';
      display: block;
      margin: 20px;
      width: 70px;
      height: 3px;
      background-color: #fce38a; }
  .l-about-me .container {
    position: absolute;
    margin: 0 auto;
    right: 0;
    left: 0;
    z-index: 10; }
    .l-about-me .container p {
      padding: 15px;
      background: rgba(0, 0, 0, 0.5);
      margin-bottom: 2px; }
      .l-about-me .container p span {
        color: #fce38a; }

.l-projects {
  background-color: #141414;
  position: relative;
  margin-bottom: 500px; }
  .l-projects::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -25vh;
    width: 0;
    border-top: 25vh solid #141414;
    border-right: 100vw solid transparent; }
    @media screen and (max-width: 575px) {
      .l-projects::after {
        bottom: -20vh;
        border-top: 20vh solid #141414;
        border-right: 200vw solid transparent; } }
  .l-projects h4 {
    text-transform: uppercase;
    text-align: right; }
  .l-projects p {
    font-size: 11px;
    margin-bottom: 5px;
    text-align: right; }
  .l-projects .card-img {
    position: relative;
    overflow: hidden;
    width: 351px;
    height: 200px;
    color: #ffffff;
    font-size: 16px;
    background-color: #000000; }
    @media screen and (max-width: 440px) {
      .l-projects .card-img {
        width: 308px;
        height: 137px; } }
    .l-projects .card-img::after {
      position: absolute;
      top: 0;
      bottom: 0;
      left: -30%;
      right: 70%;
      content: '';
      background-color: rgba(0, 0, 0, 0.7);
      -webkit-transform: skew(20deg) translateX(-75%);
              transform: skew(20deg) translateX(-75%);
      -webkit-transition: all 0.35s ease;
      transition: all 0.35s ease; }
    .l-projects .card-img img {
      width: 100%;
      -webkit-transition: all 0.35s ease;
      transition: all 0.35s ease; }
    .l-projects .card-img * {
      box-sizing: border-box; }
    .l-projects .card-img figcaption {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      padding: 20px; }
    .l-projects .card-img .links {
      font-size: 13px;
      letter-spacing: 3px;
      font-weight: 300;
      text-transform: uppercase; }
    .l-projects .card-img .links li {
      height: 35px;
      -webkit-transform: translateX(40px);
              transform: translateX(40px);
      opacity: 0;
      list-style: none;
      -webkit-transition: all 0.35s ease;
      transition: all 0.35s ease; }
      @media screen and (max-width: 440px) {
        .l-projects .card-img .links li {
          height: 30px; } }
    .l-projects .card-img a {
      display: block;
      color: #ffffff;
      text-decoration: none;
      -webkit-transition: all 0.35s ease;
      transition: all 0.35s ease; }
      .l-projects .card-img a:hover {
        color: #ff9932; }
  .l-projects .card-img:hover:after,
  .l-projects .card-img.hover:after {
    -webkit-transform: skew(20deg) translateX(0%);
            transform: skew(20deg) translateX(0%); }
  .l-projects .card-img:hover img,
  .l-projects .card-img.hover img {
    zoom: 1;
    opacity: 0.5;
    -webkit-transition: all 0.35s ease;
    transition: all 0.35s ease; }
  .l-projects .card-img:hover li,
  .l-projects .card-img.hover li {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px); }
  .l-projects .card-img:hover li:first-child,
  .l-projects .card-img.hover li:first-child {
    -webkit-transition-delay: 0.1s;
            transition-delay: 0.1s; }
  .l-projects .card-img:hover li:nth-child(2),
  .l-projects .card-img.hover li:nth-child(2) {
    -webkit-transition-delay: 0.2s;
            transition-delay: 0.2s; }
  .l-projects .card-img:hover li:nth-child(3),
  .l-projects .card-img.hover li:nth-child(3) {
    -webkit-transition-delay: 0.3s;
            transition-delay: 0.3s; }
  .l-projects .card-img:hover li:nth-child(4),
  .l-projects .card-img.hover li:nth-child(4) {
    -webkit-transition-delay: 0.4s;
            transition-delay: 0.4s; }

.l-footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100vh;
  background-image: url(/static/media/footer-bg.ac284db8.jpg);
  background-size: cover;
  background-position: center; }
  .l-footer article {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 15px;
    margin-bottom: 50px; }
    @media screen and (max-width: 768px) {
      .l-footer article {
        margin: 0 0 5px 0 !important; } }
  .l-footer article:nth-child(2) {
    text-align: center;
    margin: 0 30px 50px 30px; }
    @media screen and (max-width: 768px) {
      .l-footer article:nth-child(2) {
        width: 45%; } }
    @media screen and (max-width: 575px) {
      .l-footer article:nth-child(2) {
        max-width: 100%;
        width: 100%; } }
  @media screen and (max-width: 768px) {
    .l-footer article:nth-child(3) {
      width: 40%; } }
  @media screen and (max-width: 575px) {
    .l-footer article:nth-child(3) {
      max-width: 100%;
      width: 100%; } }
  @media screen and (min-width: 768px) {
    .l-footer article:nth-child(3) h5 {
      text-align: right; } }
  .l-footer article:nth-child(3) p {
    margin-bottom: 10px; }
    @media screen and (max-width: 1024px) {
      .l-footer article:nth-child(3) p {
        font-size: 13px; } }
  .l-footer .footer-blocks {
    width: 100%; }
    @media screen and (max-width: 768px) {
      .l-footer .footer-blocks {
        flex-wrap: wrap; } }
    @media screen and (max-width: 575px) {
      .l-footer .footer-blocks {
        width: auto; } }
  .l-footer ul li {
    width: 100%;
    height: 45px;
    justify-content: flex-start; }
    .l-footer ul li .link {
      font-size: 16px; }
  @media screen and (max-width: 768px) {
    .l-footer .site-map ul {
      display: flex; } }
  .l-footer .follow-me .social-link {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: black;
    width: 50px;
    margin: 10px 10px;
    height: 27.5px;
    background: #fce38a;
    position: relative;
    -webkit-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out; }
    .l-footer .follow-me .social-link:hover {
      color: white;
      background: rgba(38, 38, 38, 0.9);
      -webkit-transition: all 0.35s ease-in-out;
      transition: all 0.35s ease-in-out; }
      .l-footer .follow-me .social-link:hover::before, .l-footer .follow-me .social-link:hover::after {
        -webkit-transition: all 0.35s ease-in-out;
        transition: all 0.35s ease-in-out;
        border-bottom-color: rgba(38, 38, 38, 0.9);
        border-top-color: rgba(38, 38, 38, 0.9); }
  .l-footer .follow-me .social-link:nth-child(2n-1) {
    margin-top: 52px; }
  .l-footer .follow-me .social-link:nth-child(2n) {
    margin-left: -34px;
    margin-right: -34px; }
  .l-footer .follow-me .linkedin:hover {
    color: #0077b5; }
  .l-footer .follow-me .telegram:hover {
    color: #0088cc; }
  .l-footer .follow-me .skype:hover {
    color: #00aff0; }
  .l-footer .follow-me .pinterest:hover {
    color: #bd081c; }
  .l-footer .follow-me .social-link::before {
    content: "";
    position: absolute;
    top: -12.5px;
    left: 0;
    width: 0;
    height: 0;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    border-bottom: 12.5px solid #fce38a;
    -webkit-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out; }
  .l-footer .follow-me .social-link::after {
    content: "";
    position: absolute;
    bottom: -12.5px;
    left: 0;
    width: 0;
    height: 0;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    border-top: 12.5px solid #fce38a;
    -webkit-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out; }
  .l-footer .footer-panel {
    width: 100vw;
    height: 50px;
    background-color: rgba(0, 0, 0, 0.8);
    font-size: 13px; }
    .l-footer .footer-panel .container {
      width: 100%;
      margin: 0 auto; }

.l-cv-banner {
  width: 100vw;
  height: 35vh;
  background-image: url(/static/media/cv-bg.a114bdcd.jpg);
  background-position: center;
  background-size: cover;
  position: relative; }
  .l-cv-banner::after {
    content: '';
    position: absolute;
    width: 0;
    bottom: 0;
    right: 0;
    border-bottom: 15vh solid #f0f0f0;
    border-left: calc(100vw - 300px) solid transparent; }
  .l-cv-banner::before {
    content: '';
    position: absolute;
    width: 0;
    bottom: 0;
    left: 0;
    border-bottom: 10vh solid #262626;
    border-right: 400px solid transparent; }
  .l-cv-banner .profile-photo {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 4px solid #f0f0f0;
    position: absolute;
    object-fit: cover;
    left: 225px;
    top: calc(30vh - 50px);
    z-index: 10; }

.l-cv-main {
  display: flex;
  width: 100vw;
  height: 155vh;
  z-index: 100;
  background-color: #f0f0f0;
  overflow: hidden; }
  .l-cv-main::after {
    content: '';
    width: 0;
    position: absolute;
    bottom: -20vh;
    left: 0;
    border-top: 20vh solid #f0f0f0;
    border-left: 100vw solid transparent; }
    @media screen and (max-width: 575px) {
      .l-cv-main::after {
        bottom: -10vh;
        border-top: 10vh solid #f0f0f0; } }
  @media screen and (max-width: 768px) {
    .l-cv-main {
      flex-direction: column;
      height: auto; } }
  .l-cv-main .side-part {
    width: 400px;
    height: 100%;
    background-color: #262626;
    padding: 20px; }
    @media screen and (min-width: 768px) {
      .l-cv-main .side-part::after {
        content: '';
        width: 0;
        position: absolute;
        bottom: 0;
        left: -500px;
        border-bottom: 20vh solid #f0f0f0;
        border-right: 100vw solid transparent; } }
    @media screen and (max-width: 768px) {
      .l-cv-main .side-part {
        width: 100%; } }
    .l-cv-main .side-part h4 {
      color: white;
      font-family: "Montserrat", sans-serif; }
      .l-cv-main .side-part h4::after {
        content: '';
        display: block;
        width: 70px;
        height: 3px;
        background-color: #fce38a;
        margin-top: 10px; }
    .l-cv-main .side-part p {
      font-size: 14px;
      padding-bottom: 5px; }
    .l-cv-main .side-part .name {
      font-size: 18px; }
    .l-cv-main .side-part .language-paragraph {
      width: 115px; }
    .l-cv-main .side-part .romb {
      width: 11px;
      height: 11px;
      background-color: #f0f0f0;
      -webkit-transform: skew(25deg);
              transform: skew(25deg);
      margin: 0 3px; }
    .l-cv-main .side-part .empty-romb {
      background-color: slategray; }
  .l-cv-main .main-part {
    width: 100%;
    height: 100%;
    background-color: #f0f0f0;
    padding: 20px; }
    @media screen and (max-width: 768px) {
      .l-cv-main .main-part {
        width: unset;
        margin-bottom: 100px; } }

.button-learn-more {
  background-color: #262626;
  border: none;
  color: #ffffff;
  outline: none;
  padding: 16px 60px 16px;
  position: relative;
  -webkit-transform: skewX(-25deg);
          transform: skewX(-25deg); }
  .button-learn-more p {
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    text-transform: uppercase;
    -webkit-transform: skewX(25deg);
            transform: skewX(25deg); }

.button-learn-more:before,
.button-learn-more:after {
  border-color: transparent;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
  border-style: solid;
  border-width: 0;
  content: "";
  height: 24px;
  position: absolute;
  width: 24px; }

.button-learn-more:before {
  border-color: #ff9932;
  border-top-width: 2px;
  left: 0px;
  top: -5px; }

.button-learn-more:after {
  border-bottom-width: 2px;
  border-color: #ff9932;
  bottom: -5px;
  right: 0px; }

.button-learn-more:hover,
.button-learn-more.hover {
  background-color: #262626; }

.button-learn-more:hover:before,
.button-learn-more.hover:before,
.button-learn-more:hover:after,
.button-learn-more.hover:after {
  height: 100%;
  width: 100%; }

.m-section-header {
  color: white;
  z-index: 100; }
  @media screen and (max-width: 575px) {
    .m-section-header h2 {
      font-size: 32px; }
    .m-section-header h3 {
      font-size: 18px; } }

.m-accordion {
  max-width: 1100px;
  width: 100%;
  margin: 0 auto; }

/* Acordeon styles */
.tab {
  position: relative;
  margin-bottom: 1px;
  width: 100%;
  overflow: hidden;
  background-color: #262626; }
  .tab input {
    position: absolute;
    opacity: 0;
    z-index: -1; }
  .tab label {
    color: white;
    position: relative;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    cursor: pointer;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out; }
    .tab label:hover {
      color: #fce38a;
      -webkit-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out; }
      .tab label:hover .icon {
        color: #fce38a;
        -webkit-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out; }
  .tab .icon {
    color: white;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out; }

.tab-content {
  background-color: #f0f0f0;
  height: 0;
  overflow: hidden;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out; }
  .tab-content p {
    margin: 1em;
    color: #262626; }
  .tab-content h4 {
    text-align: center;
    margin: 10px; }

/* :checked */
input:checked ~ .tab-content {
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out; }

#tab-1:checked ~ .tab-content {
  height: 140px; }
  @media screen and (max-width: 1200px) {
    #tab-1:checked ~ .tab-content {
      height: 160px; } }
  @media screen and (max-width: 992px) {
    #tab-1:checked ~ .tab-content {
      height: 240px; } }
  @media screen and (max-width: 768px) {
    #tab-1:checked ~ .tab-content {
      height: 240px; } }
  @media screen and (max-width: 480px) {
    #tab-1:checked ~ .tab-content {
      height: 330px; } }

#tab-2:checked ~ .tab-content {
  height: 450px; }
  @media screen and (max-width: 1200px) {
    #tab-2:checked ~ .tab-content {
      height: 465px; } }
  @media screen and (max-width: 992px) {
    #tab-2:checked ~ .tab-content {
      height: 600px; } }
  @media screen and (max-width: 768px) {
    #tab-2:checked ~ .tab-content {
      height: 600px; } }
  @media screen and (max-width: 480px) {
    #tab-2:checked ~ .tab-content {
      height: 750px; } }

#tab-3:checked ~ .tab-content {
  height: 160px; }
  @media screen and (max-width: 1200px) {
    #tab-3:checked ~ .tab-content {
      height: 180px; } }
  @media screen and (max-width: 768px) {
    #tab-3:checked ~ .tab-content {
      height: 180px; } }
  @media screen and (max-width: 480px) {
    #tab-3:checked ~ .tab-content {
      height: 210px; } }

#tab-4:checked ~ .tab-content {
  height: 130px; }
  @media screen and (max-width: 1200px) {
    #tab-4:checked ~ .tab-content {
      height: 235px; } }
  @media screen and (max-width: 768px) {
    #tab-4:checked ~ .tab-content {
      height: 235px; } }
  @media screen and (max-width: 480px) {
    #tab-4:checked ~ .tab-content {
      height: 300px; } }

#tab-5:checked ~ .tab-content {
  height: 500px; }

input:checked ~ label .icon {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out; }

input:checked ~ .tab-content .progress-container .m-progress-bar {
  width: 100%;
  -webkit-transition: all 1.5s ease-in-out;
  transition: all 1.5s ease-in-out; }

