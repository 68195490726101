//COLORS
$grey-color: #262626;
$grey-color-op: rgba(38,38,38,.9);
$yellow-color: #fce38a;
$orange-color: #ff9932;
$white-color: #ffffff;
$grey-hover-color: #d9d9d9;
$grey-bg-color: #f0f0f0;
$color-font: #333333;
//Icons color
$html-color: #E54C21;
$css-color: #0070B7;
$js-color: #F7E018;
$sass-color: #CE679A;
$jquery-color: #0868AB;
$bootstrap-color: #6D43A1;
$react-color: #61DAFB;
$git-color: #F05133;
$gulp-color: #D34A47;
$webpack-color: #80C7FF;
$npm-color: #CC3534;
$slick-color: #3498DB;
$smacss_color: #92C5E0;
//FONTS
$regular-roboto: 'Roboto', sans-serif;
$header-font: 'Montserrat', sans-serif;
$h3-font: 'Expletus Sans', cursive;