//Flexes
.d-flex {
  display: flex;
}
.center-horizontal {
  justify-content: center;
}
.center-vertical {
  align-items: center;
}
.flex-wrap {
  flex-wrap: wrap;
}
.around {
  justify-content: space-around;
}
.between {
  justify-content: space-between;
}
.text-center {
  text-align: center;
}
.black {
  color: #262626;
}

//Margins
.mt-1 {
  margin-top: 10px;
}
.mt-2 {
  margin-top: 20px;
}
.mt-3 {
  margin-top: 30px;
}
.mt-4 {
  margin-top: 40px;
}
.mt-5 {
  margin-top: 50px;
}
.mt-10 {
  margin-top: 100px;
}

.mb-1 {
  margin-bottom: 10px;
}
.mb-2 {
  margin-bottom: 20px;
}
.mb-3 {
  margin-bottom: 30px;
}
.mb-4 {
  margin-bottom: 40px;
}
.mb-5 {
  margin-bottom: 50px;
}
.mb-10 {
  margin-bottom: 100px;
}
//Paddings
.pt-1 {
  padding-top: 10px;
}
.pt-2 {
  padding-top: 20px;
}
.pt-3 {
  padding-top: 30px;
}
.pt-4 {
  padding-top: 40px;
}
.pt-5 {
  padding-top: 50px;
}
.pt-6 {
  padding-top: 60px;
}
.pt-10 {
  padding-top: 100px;
}

.pb-1 {
  padding-bottom: 10px;
}
.pb-2 {
  padding-bottom: 20px;
}
.pb-3 {
  padding-bottom: 30px;
}
.pb-4 {
  padding-bottom: 40px;
}
.pb-5 {
  padding-bottom: 50px;
}
.pb-6 {
  padding-bottom: 60px;
}
.pb-10 {
  padding-bottom: 100px;
}
//Transforms


